$(document).ready(function(){
  $("#contact_form").on("ajax:success",function(event){
    showAlertSuccess();
  }).on("ajax:error",function(event){
    showAlertError();
  })
});

function showAlertSuccess(){
  var alert=$("#alertSuccess");
  alert.addClass("show");
  alert.removeClass("hide");
  setTimeout(function(){
    alert.removeClass("show");
    alert.addClass("hide");
  },3000);
}

function showAlertError(){
  var alert=$("#alertError");
  alert.addClass("show");
  alert.removeClass("hide");
  alert.removeClass("alert-success");
  alert.addClass("alert-warning");
  setTimeout(function(){
    alert.addClass("alert-success");
    alert.removeClass("alert-warning");
    alert.removeClass("show");
    alert.addClass("hide");
  },3000);
}
