 // Place all the behaviors and hooks related to the matching controller here.
 // All this logic will automatically be available in application.js.
 // You can use CoffeeScript in this file: http://coffeescript.org/

//running funciton to display modal when the page is load
$(window).on('load', function(){
  if(getCookie("modal_shown")===""){
    $("#modalForm").fadeIn(1500);
  }
});

var tomorrow = new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toUTCString();
$(document).ready(function(){
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });
  
  $("#modalForm").on('click', function(ev) {
    if (ev.target !== this){
      return;
    }
    $("#modalForm").fadeOut(500);
    document.cookie="modal_shown=true; expires="+tomorrow+";"
  })

  $(".modal-form").submit(function(){
    event.preventDefault();
    var email = $('form #email').val();
    $.ajax({
      type: "POST",
      url: "/send_email",
      data: { address: email },

    })
    $("#modalForm").fadeOut(500);
    document.cookie="modal_shown=true;"
  })
})

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}
