function btn1(){
  $('.part-1').fadeOut(400);
  $('.part-2').delay(400).fadeIn(400);
}

function btn2(){
  $('.part-2').fadeOut(400);
  $('.part-3').delay(400).fadeIn(400);
}

function bck1(){
  $('.part-2').fadeOut(400);
  $('.part-1').delay(400).fadeIn(400);
}

function bck2(){
  $('.part-3').fadeOut(400);
  $('.part-2').delay(400).fadeIn(400);
}
