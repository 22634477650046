
function createDiv() {
  var title=$('#title').val();
  var area = $('#text-area').val();
  if (title.length == 0) {
    $('.msg-alert').html('You need to add a title').fadeIn().delay(800).fadeOut();

  } else if(area.length == 0){
    $('.msg-alert').html('You need to add content').fadeIn().delay(800).fadeOut();
  } else {
    var div = $("<div class='post draggable ui-widget-content'><div class='post-header ui-widget-header'><span onClick='toggleContent(this)' class='ui-icon ui-icon-minusthick post-toggle'></span>"+title+"</div><div class='post-content'>"+area+"</div></div>");
    $('.cards-container').append(div);
    $('#myform')[0].reset();
  }
}


function toggleContent(obj) {
  console.log("toggling")
  var icon = $( obj );
  icon.toggleClass( "ui-icon-minusthick ui-icon-plusthick" );
  icon.closest( ".post" ).find( ".post-content" ).toggle();
  icon.closest(".post").css({height: 'auto'});
};

$(document).ready(function() {
    $('.project-col').sortable({
      connectWith: ".project-col, .cards-container, .panel-footer",
      cancel: ".post-toggle",
      placeholder: "post-placeholder",
      scroll: false,

    });
    $('.cards-container').sortable({
      scroll: false,
      connectWith: ".project-col, .panel-footer"
    });
    $('.panel-footer').sortable({
      receive: function( event, ui ) {
        $(ui.item).remove();
      },
      over: function( event, ui ) {
        $('.panel-footer').css({'background-color': '#616161', 'color': 'white'});
        $('.post-placeholder').css({'border': '2px dotted white', 'height': '35px', 'border-radius': '5px'});
      },
      out: function( event, ui ) {
        $('.panel-footer').css({'background-color': 'white', 'color': '#424242'});
      }
  });
})
